* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #f1f6f9;
  font-family: "Titillium Web", sans-serif;
  font-size: 1rem;
  text-decoration: none;
  color: #444444;
  font-weight: 200;
}

/* background colors */

.darker-back {
  background-color: #000c19;
}

.dark-back {
  background-color: #070523;
}

.light-back {
  background-color: #041339;
}

.lighter-back {
  background-color: #88e1f2;
}

.white-background {
  background-color: white;
}

/* global text styling */

h1 {
  font-size: 3rem;
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
}

h3 {
  font-size: 1.8rem;
}

h4 {
  font-size: 1.5rem;
}

.golden-text {
  color: rgb(195, 167, 105);
}
.darker-text {
  color: #222222;
}

.dark-text {
  color: #a361bd;
}

.cool-text {
  color: #e3ba80;
}

.light-text {
  color: rgb(241, 241, 241);
}

.white-text {
  color: white;
}

.small-text {
  font-size: 0.9rem;
}

.medium-text {
  font-size: 1.3rem;
}

.large-text {
  font-size: 1.8rem;
}

.giant-text {
  font-size: 3rem;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

/* buttons */

.button {
  padding: 10px 0;
  color: white;
  background-color: #25d366;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-out;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 5px;
}

a {
  text-decoration: none;
  color: inherit;
}
.button-width {
  width: 250px;
}

.button:hover {
  background-color: #ebf61d;
}

.bar-button {
  font-size: 1rem;
}
/* global centering */

.position {
  position: relative;
}

.center-all {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.center {
  position: relative;
}

.vertical {
  position: relative;
  top: 50%;
  transform: translatey(-50%);
}

.right-side {
  position: absolute;
  right: 0;
}

/* global padding */

.padding20 {
  padding: 20px;
}

.padding20v {
  padding: 20px 0;
}

.padding50 {
  padding: 50px;
}

.padding50v {
  padding: 50px 0;
}

.margin20v {
  margin: 20px 0;
}

.relative {
  position: relative;
}

/* displays */

.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.bar {
  display: flex;
  justify-content: center;
}

.load-more {
  background-color: "red";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 25px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  color: red;
}

.load-more:hover {
  font-size: 1.2rem;
  color: red;
}
