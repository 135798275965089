.carouselContain{
    position: relative;
}

.carouselContain img{
    object-fit: cover;
}

.theCarousel {
    position: relative;
    height: calc(100vh - 70px);
    width: 100%;
    margin-bottom: 100px;
}

.carousel-item img {
    height: calc(100vh - 70px) !important;
}

/*********** 991px MAX-WIDTH ***********/

@media screen and (max-width:991px) {
    .theCarousel {
        height: calc(80vh - 70px);
    }

    .carousel-item img {
        height: calc(80vh - 70px) !important;
    }
}

/*********** 576px MAX-WIDTH ***********/

@media screen and (max-width:576px) {
    
    .carouselContain .mainTitle{
        font-size: 40px;
    }

    
    .theCarousel {
        height: calc(60vh - 70px);
    }

    .carousel-item img {
        height: calc(60vh - 70px) !important;
    }
}

/*********** 428px MAX-WIDTH ***********/

@media screen and (max-width:428px) {
    .carouselContain{
        margin-top: 70px;
    }

    .theCarousel{
        margin-bottom: 50px;
    }

    .carouselContain .titleContain{
        right: 0;
        left: 0;
        bottom: auto;
        top: -70px;
    }
    
    .carouselContain .mainTitle{
        width: 100%;
        border-radius: 1px !important;
        font-size: 30px;
    }

    .venecitas, .aquavation, .piedras{
        font-size: 20px !important;
    }

    .carouselContain .venecitas, .carouselContain .aquavation, .carouselContain .piedras{
        top: -60px !important;
    }

    .theCarousel {
        height: calc(40vh - 70px);
    }

    .carousel-item img {
        height: calc(40vh - 70px) !important;
    }
}

/*********** 360px MAX-WIDTH ***********/

@media screen and (max-width:360px) {
    .theCarousel {
        height: calc(40vh - 70px);
    }

    .carousel-item img {
        height: calc(40vh - 70px) !important;
    }
}