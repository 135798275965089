.banner4 {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 100px 0;
  max-width: 1400px;
  width: 100%;
  justify-content: space-between;
}

.banner4-photo {
  width: 480px;
  height: 300px;
  object-fit: cover;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.28);
  border: solid 5px white;
}

.buttonw2 {
  padding: 10px 0;
  color: white;
  background-color: #25d366;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-out;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 5px;
  transform: translateX(-50%);
  margin-bottom: 80px;
}

.buttonw2-width {
  width: 250px;
}

.buttonw2:hover {
  background-color: #8bd1ff;
}

.banner3 {
  position: relative;
  display: flex;
  margin: 100px 0;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.banner2-photo {
  width: 300px;
  margin: 0 20px;
}

.paragraph {
  margin: 15px 0;
}
@media (max-width: 1000px) {
  .banner4 {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    margin: 10px 0;
    max-width: 90%;
  }
  .banner-pic {
    width: 300px;
    margin-top: 70px;
  }

  .buttonw-width {
    margin-top: 50px;
  }

  .banner3 {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 10px 0;
  }

  .banner2-photo {
    width: 300px;
    margin: 30px 20px;
  }

  .banner4-photo {
    margin: 20px 0;
    width: 95%;
    height: auto;
  }
}

@media (max-width: 470px) {
  .banner2-photo {
    width: 80%;
  }
  .buttonw2 {
    margin-top: 40px;
  }
}
