.banner1 {
  position: relative;
  width: 100%;
  margin-bottom: 100px;
}

.mainBG {
  height: calc(100vh - 70px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.mainBG .main-banner {
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.mainBG .main-logo {
  height: 80px;
  width: auto;
  z-index: 1;
  margin: 0px 0px 20px 0px;
}

.mainBG h1 {
  width: 100%;
  z-index: 1;
  font-weight: 700;
  font-size: 80px;
  padding: 5px 30px;
  text-align: center;
  color: rgb(19, 12, 92);
}

.mainBG h2 {
  width: 100%;
  z-index: 1;
  font-weight: 600;
  font-size: 30px;
  padding: 5px 30px;
  text-align: center;
  color: rgb(19, 12, 92);
}

.mainLink {
  text-decoration: none !important;
}

.main-btn-container {
  position: relative;
  margin-top: 80px;
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  overflow: hidden;
}

.main-btn {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 100px;
  width: 250px;
  padding-bottom: 5px;
  background-color: rgba(19, 12, 92, 0.93);
  border-bottom: solid 1px rgba(19, 12, 92, 0.93);
  z-index: 1;
  cursor: pointer;
  margin: 3px;
  transition: all 200ms ease-in;
  border-radius: 4px;
  padding-top: 10px;
}

.main-btn:hover {
  background-color: rgb(14, 10, 58);
  opacity: 0.9;
  transform: scale(0.97);
}

.main-btn-icon {
  font-size: 35px;
  margin-bottom: 8px;
}

.main-btn h3 {
  font-size: 20px;
  font-weight: 600;
}

.mainText {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: flex-start;
  padding: 0 350px;
  margin: 100px 0px;
}

.mainText h2 {
  align-self: flex-start;
  font-size: 30px;
  margin: 20px;
  color: rgb(19, 12, 92);
  border-bottom: 2px solid rgb(237, 237, 244);
  padding-bottom: 20px;
}

.mainText p {
  font-size: 18px;
  margin: 15px 0px;
  color: rgba(39, 34, 101, 0.93);
  font-weight: 600;
  text-align: justify;
}

.btn-success {
  position: fixed !important;
  bottom: 30px;
  right: 20px;
  padding: 10px !important;
  height: auto !important;
  width: auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0) !important;
  border: none !important;
  z-index: 555;
}

.waIcon {
  position: relative;
  font-size: 50px;
  width: 60px;
  color: rgb(19, 12, 92);
  /* background-color: #fff; */
  padding: 0px;
  cursor: pointer;
  transition: all 200ms ease-in;
}

/*********** 991px MAX-WIDTH ***********/

@media screen and (max-width: 991px) {
  .mainBG .main-logo {
    height: 55px;
    margin: 20px 0px 20px 0px;
  }

  .mainBG h1 {
    font-size: 65px;
  }

  .mainBG h2 {
    font-size: 25px;
  }

  .main-btn-container {
    margin-top: 50px;
  }

  .main-btn {
    height: 95px;
    width: 220px;
  }

  .main-btn-icon {
    font-size: 30px;
  }

  .main-btn h3 {
    font-size: 22px;
  }

  .mainText {
    padding: 0 200px;
  }

  .mainText h2 {
    font-size: 25px;
    margin: 18px;
  }

  .mainText p {
    font-size: 16px;
    margin: 13px 0px;
    text-align: justify;
  }
}

/*********** 768px MAX-WIDTH ***********/

@media screen and (max-width: 768px) {
  .mainBG .main-logo {
    height: 50px;
    margin: 20px 0px 20px 0px;
  }

  .mainBG h1 {
    font-size: 50px;
  }

  .mainBG h2 {
    font-size: 20px;
  }

  .main-btn-container {
    margin-top: 35px;
  }

  .main-btn {
    height: 95px;
    width: 190px;
  }

  .main-btn-icon {
    font-size: 25px;
  }

  .main-btn h3 {
    font-size: 18px;
  }

  .mainText {
    padding: 0 200px;
  }

  .mainText h2 {
    font-size: 25px;
    margin: 18px;
  }

  .mainText p {
    font-size: 16px;
    margin: 13px 0px;
    text-align: justify;
  }
}

/*********** 576px MAX-WIDTH ***********/

@media screen and (max-width: 576px) {
  .mainBG .main-logo {
    height: 50px;
    margin: 0px 0px 10px 0px;
  }

  .mainBG h1 {
    font-size: 60px;
  }

  .mainBG h2 {
    font-size: 25px;
  }

  .main-btn-container {
    margin-top: 35px;
  }

  .main-btn {
    height: 70px;
    width: 190px;
  }

  .main-btn-icon {
    font-size: 25px;
  }

  .main-btn h3 {
    font-size: 18px;
  }

  .mainText {
    margin-top: 40px;
    padding: 0 50px;
  }

  .mainText h2 {
    font-size: 25px;
    margin: 18px;
  }

  .mainText p {
    font-size: 16px;
    margin: 13px 0px;
    text-align: justify;
  }
}

/*********** 428px MAX-WIDTH ***********/

@media screen and (max-width: 428px) {
  .mainBG .main-logo {
    height: 45px;
    margin: 0px 0px 10px 0px;
  }

  .mainBG h1 {
    font-size: 50px;
  }

  .mainBG h2 {
    font-size: 25px;
  }

  .main-btn-container {
    margin-top: 20px;
    margin-bottom: 0;
    flex-direction: column;
  }

  .main-btn {
    height: 85px;
    width: 250px;
    padding-top: 10px;
    transform: scale(0.9);
  }

  .main-btn-icon {
    font-size: 40px;
  }

  .main-btn h3 {
    font-size: 15px;
  }

  .mainText {
    padding: 0 50px;
  }

  .mainText h2 {
    font-size: 25px;
    margin: 18px;
  }

  .mainText p {
    font-size: 15px;
    margin: 13px 0px;
    text-align: justify;
    opacity: 0.9;
  }
}

/*********** 360px MAX-WIDTH ***********/

@media screen and (max-width: 360px) {
  .mainBG .main-logo {
    height: 40px;
    margin: 0px 0px 10px 0px;
  }

  .mainBG h1 {
    font-size: 40px;
  }

  .mainBG h2 {
    font-size: 22px;
  }

  .main-btn-container {
    margin-top: 10px;
    margin-bottom: 0;
    flex-direction: column;
  }

  .main-btn {
    height: 80px;
    width: 240px;
  }

  .main-btn-icon {
    font-size: 25px;
  }

  .main-btn h3 {
    font-size: 18px;
  }

  .mainText {
    padding: 0 20px;
  }

  .mainText h2 {
    font-size: 25px;
    margin: 18px;
  }

  .mainText p {
    font-size: 15px;
    margin: 13px 0px;
    opacity: 0.9;
  }
}
