.banner-construccion {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 20px 0;
  width: fit-content;
  max-width: 1400px;
  justify-content: space-around;
}

.titleContain {
  position: absolute;
  top: 25px;
  /* bottom: 0px; */
  /* left: 0; */
  right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.mainTitle {
  background-color: #ffffff;
  color: rgba(19, 12, 92);
  font-weight: 700;
  font-size: 50px;
  padding: 10px;
  border-radius: 3px;
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px rgba(19, 12, 92);
}

.const-photo {
  width: 400px;
  margin: 0 20px;
}

.icons {
  font-size: 5rem;
  margin: 20px 0;
}

.fa-hammer {
  margin-right: 40px;
  color: #8bd1ff;
}

.fa-tools {
  margin-right: 40px;
  color: #c8db82;
}

.buttonw3 {
  padding: 10px 0;
  color: white;
  background-color: #25d366;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-out;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 5px;
  transform: translateX(-50%);
  margin: 80px 0;
}

.buttonw3-width {
  width: 250px;
}

.buttonw2:hover {
  background-color: #8bd1ff;
}

.text-holder {
  width: 90%;
  max-width: 800px;
}

@media (max-width: 1280px) {
  .const-photo {
    width: 300px;
    margin: 0 15px;
  }
}

@media (max-width: 1016px) {
  .const-photo {
    width: 100%;
    max-height: 250px;
    max-width: 700px;
    margin: 20px 0;
    position: relative;
    object-fit: cover;
    left: 50%;
    transform: translateX(-50%);
  }

  .banner-construccion {
    grid-template-columns: 1fr;
  }
}
