.navbarBS {
    min-height: 70px;
}

.logoContainer {
    height: 100%;
    padding-top: 16.5px !important;
    padding-bottom: 16.5px !important;
    width: auto;
}

.logoContainer img {
    height: 40px;
    width: auto;
}

.nav-element {
    cursor: pointer;
    transition: all 0.3s ease-out;
    text-decoration: none !important;
    position: relative;
    padding: 2px 0px !important;
    height: 100%;
    margin: auto 0;
    font-weight: 700;
    color: rgb(19, 12, 92) !important;
}

.nav-element p {
    margin-bottom: 0px !important;
    font-weight: 600;
    font-size: 16px;
}

.nav-element p:hover {
    color: rgb(19, 12, 92) !important;
}

.container {
    height: 100% !important;
}

.navbar {
    position: relative !important;
    top: 0;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0) !important;
    width: 100%;
    font-size: 18px;
    font-weight: 800;
    padding: 0px !important;
    padding-top: 50px;
}


.logoClass {
    color: #fff !important;
    font-size: 45px !important;
    font-family: 'Rancho', cursive;
    margin: 0px !important;
    padding: 0px !important;
    padding-top: 16.5px !important;
}

.me-auto {
    margin-right: none !important;
    padding-top: 5px;
}

.navbar-nav {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.nav-link {
    color: #fff !important;
    margin: 5px 7px !important;
    position: relative;
    border-radius: 2px;
    transition: all 600ms ease-in !important;
}

.nav-span {
    position: absolute;
    bottom: -2px;
    height: 2px;
    left: 25%;
    right: 85%;
    background-color: rgb(19, 12, 92);
    padding: 0px !important;
    margin: 0px !important;
    /* border-radius: 100px; */
    transition: all 300ms ease-out;
}

.nav-element:hover .nav-span {
    right: 25%;
}

.navbar-toggler {
    position: relative;
    background-color: #fff !important;
    color: rgb(13, 41, 96) !important;
    border: solid 3px rgb(13, 41, 96) !important;
    border-radius: 3px !important;
    text-decoration: none;
    width: 40px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-toggler-icon {
    position: absolute;
    background-image: none !important;
    width: 40% !important;
    height: 40% !important;
    border-top: solid 3px rgb(13, 41, 96);
    border-bottom: solid 3px rgb(13, 41, 96);
    transition: all 200ms ease-in-out;
    animation-duration: 700ms;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

.navbar-toggler:hover .navbar-toggler-icon {
    transform: rotate(90deg);
    height: 60% !important;
}

/*********** 428px MAX-WIDTH ***********/

@media screen and (max-width:428px) {
    .logoContainer img {
        height: 30px;
        width: auto;
    }
}