.contactoContainer {
    position: relative;
    height: calc(100vh - 70px);
    width: 100%;
    background-image: url(/src/Assets/water-20.gif);
    background-position: center;
    background-size: cover;
}

.contactoOverlay{
    height: 100%;
    width: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255) 0%, rgba(21, 14, 86, 0.0) 100%);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.contactTitle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    width: auto;
    padding: 70px;
}

.contactTitle h1{
    font-size: 80px;
    margin: 30px 0px;
    color:rgba(19, 12, 92) ;
    font-weight: 700;
}

.contactTitle img{
    align-self: center;
    height: 90px;
    width: auto;
    margin: 30px 0;
}

.contactTitle h4{
    color: rgba(19, 12, 92);
    font-size: 18px;
    width: 500px;
}

/*rgba(90, 85, 156, 0.93)*/

.contactForm{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contactForm div{
    width: 90%;
    margin: 10px;
    padding: 0px !important;
}

.contactForm input{
    padding: 15px;
    font-size: 22px;
    background-color: rgba(240, 248, 255, 0);
    border: none;
    color: rgba(19, 12, 92) !important;
    font-weight: 700;
    border-bottom: solid 1px rgba(19, 12, 92);
    border-radius: 0px;
    font-family: "Titillium Web", sans-serif;
    text-transform: uppercase;
}

.contactForm textarea{
    text-transform: uppercase;
    padding: 15px;
    font-size: 22px;
    background-color: rgba(240, 248, 255, 0);
    border: none;
    color: rgba(19, 12, 92) !important;
    font-weight: 700;
    border-bottom: solid 1px rgba(19, 12, 92);
    border-radius: 0px;
    font-family: "Titillium Web", sans-serif;
}

.form-control::placeholder{
    color: rgba(19, 12, 92) !important;
}

.form-control:focus {
    color: rgba(19, 12, 92) !important;
    background-color: rgba(255, 255, 255, 0);
    border-color: transparent !important;
    border-bottom: solid 1px rgba(19, 12, 92) !important;
    outline: 0;
    box-shadow: none !important;
}

.contactForm button{
    width: 90%;
    margin: 30px 10px;
    border-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border: solid 1px rgba(19, 12, 92, 0.93);
    background-color: rgba(19, 12, 92, 0.93);
    font-size: 20px;
    font-weight: 600;
}

.contactForm button:hover{
    background-color: rgba(19, 12, 92);
    border: solid 1px rgba(19, 12, 92, 0.93);
    border-bottom: solid 1px #fff;
}

/*********** 768px MAX-WIDTH ***********/

@media screen and (max-width:768px){
    .contactTitle{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;
        width: auto;
        padding: 30px;
    }
    
    .contactTitle h1{
        font-size: 60px;
        margin: 40px 0px;
        color:rgba(19, 12, 92) ;
        font-weight: 700;
    }
    
    .contactTitle img{
        align-self: center;
        height: 60px;
        width: auto;
        margin: 25px 0;
    }
    
    .contactTitle h4{
        color: rgba(19, 12, 92);
        font-size: 14px;
        width: 360px;
    }
    
    .contactForm{
        height: 100%;
        width: 100%;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    
    .contactForm div{
        width: 90%;
        margin: 5px;
        padding: 0px !important;
    }
    
    .contactForm input{
        padding: 10px;
        font-size: 18px;
    }
    
    .contactForm textarea{
        padding: 10px;
        font-size: 18px;
    }
    
    .contactForm button{
        width: 90%;
        padding: 10px;
        font-size: 16px;
    }
    
}

/*********** 576px MAX-WIDTH ***********/

@media screen and (max-width:576px){
    .contactTitle{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;
        width: auto;
        padding: 30px;
    }
    
    .contactTitle h1{
        font-size: 40px;
        margin: 40px 0px;
    }
    
    .contactTitle img{
        align-self: center;
        height: 40px;
        margin: 20px 0;
    }
    
    .contactTitle h4{
        color: rgba(19, 12, 92);
        font-size: 13px;
        width: 260px;
    }
    
    .contactForm{
        height: 100%;
        width: 100%;
        margin-top: 40px;
    }
    
    .contactForm div{
        width: 90%;
        margin: 5px;
        padding: 0px !important;
    }
    
    .contactForm input{
        padding: 7px;
        font-size: 16px;
    }
    
    .contactForm textarea{
        padding: 7px;
        font-size: 16px;
    }
    
    .contactForm button{
        width: 90%;
        padding: 7px;
        font-size: 14px;
    }
    
}

/*********** 428px MAX-WIDTH ***********/

@media screen and (max-width:428px){
    .contactoOverlay{
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        padding-top: 10px;
    }

    .contactTitle{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 40%;
        width: 100%;
        width: auto;
        padding: 40px 0px;
    }
    
    .contactTitle h1{
        font-size: 40px;
        margin: 20px 0px;
    }
    
    .contactTitle img{
        align-self: center;
        height: 40px;
        margin: 10px 0; 
    }
    
    .contactTitle h4{
        color: rgba(19, 12, 92);
        font-size: 13px;
        width: 80%;
        text-align: justify;
    }
    
    .contactForm{
        height: 100%;
        width: 100%;
        margin-top: 5px;
    }

    
    .contactForm .inputsContain{
        width: 85%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px 0px;
        padding: 0px !important;
    }
    
    .contactForm input{
        padding: 4px;
        font-size: 14px;
        width: 100%;
    }

    .inputStyle{
        width: 100%;
    }
    
    .contactForm textarea{
        padding: 4px;
        font-size: 14px;
    }
    
    .contactForm button{
        width: 90%;
        padding: 7px;
        font-size: 14px;
    }
    
}