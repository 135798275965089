.footer {
  height: 290px;
  width: 100%;
  color: rgb(19, 12, 92);
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerContain{
  width: 70%;
  height: 100%;
  border-top: solid 2px rgb(19, 12, 92);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
}

.footer-logo {
  width: 200px;
  margin: 15px;
}

.footerContain p{
  margin: 3px;
  color: rgba(77, 75, 104, 0.93);
  font-weight: 600;
}

.instagram {
  margin: 15px;
  font-size: 2.5rem;
  cursor: pointer;
  text-decoration: none;
  color: rgb(19, 12, 92);
}


/*********** 576px MAX-WIDTH ***********/

@media screen and (max-width:576px) {

  .footer{
    height: 270px;
  }

  .footer-logo {
    width: 200px;
    margin: 15px;
  }
  
  .footerContain p{
    margin: 3px;
    color: rgba(77, 75, 104, 0.93);
    font-weight: 600;
    font-size: 14px;
  }
  
  .instagram {
    margin: 15px;
    font-size: 2.2rem;
    cursor: pointer;
    text-decoration: none;
    color: rgb(19, 12, 92);
  }
  
}

/*********** 428px MAX-WIDTH ***********/

@media screen and (max-width:428px) {

  .footer{
    height: 270px;
  }

  .footer-logo {
    width: 180px;
    margin: 15px;
  }
  
  .footerContain p{
    margin: 3px;
    color: rgba(77, 75, 104, 0.93);
    font-weight: 600;
    font-size: 12px;
  }
  
  .instagram {
    margin: 15px;
    font-size: 1.8rem;
    cursor: pointer;
    text-decoration: none;
    color: rgb(19, 12, 92);
  }
  
}